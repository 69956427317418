<template>
	<section>
		<div class="container py-4">
			<div class="row">
				<div class="col-12 text-center">
					<h1 class="blue text-bold" v-html="$t('PRODUCTS SERVICES')"></h1>
				</div>
			</div>
			<div class="row">
				<a id="products"></a>
				<div class="col-md-10 offset-md-1 text-center">
					<h2><b v-html="$t('Our Products')"></b></h2>
				</div>
				<div class="col-md-10 offset-md-1 text-center">
					<p v-html="$t('Our Products Detail')"></p>
				</div>
			</div>
		</div>

		<div class="container-lg py-5 bg-product">
			<div class="container">
				<div class="row">
					<u-animate-container class="col-12 text-center">
						<u-animate class="product-box" name="flipInY">
							<div class="icon"><img src="@/assets/images/logo-product1.svg" /></div>
							<div class="title" v-html="$t('HEALTH APP')"></div>
							<div class="more"><a href="#health" v-html="$t('READ MORE')"></a></div>
						</u-animate>
						<u-animate class="product-box" name="flipInY">
							<div class="icon"><img src="@/assets/images/logo-product2.svg" /></div>
							<div class="title" v-html="$t('CLINIC MANAGEMENT')"></div>
							<div class="more"><a href="#clinic" v-html="$t('READ MORE')"></a></div>
						</u-animate>
						<u-animate class="product-box" name="flipInY">
							<div class="icon"><img src="@/assets/images/logo-product3.svg" /></div>
							<div class="title">HEALTH MARKETPLACE</div>
							<div class="more"><a href="#market" v-html="$t('READ MORE')"></a></div>
						</u-animate>
						<u-animate class="product-box" name="flipInY">
							<div class="icon"><img src="@/assets/images/logo-product4.svg" /></div>
							<div class="title">REFERRAL SYSTEM</div>
							<div class="more"><a href="#referral" v-html="$t('READ MORE')"></a></div>
						</u-animate>
					</u-animate-container>
				</div>
			</div>
		</div>

		<div class="container-lg py-5 bg-product2">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center my-4">
						<a id="health"></a>
						<h2 class="strike-left"><b v-html="$t('HEALTH APP')"></b></h2>
					</div>
					<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/health-app-slide1.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-2 number">1</div>
									<div class="col-10 text">
										<p v-html="$t('health_app_1')"></p>
									</div>
								</div>
							</div>
						</div>

						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/health-app-slide2.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-2 number">2</div>
									<div class="col-10 text">
										<p v-html="$t('health_app_2')"></p>
									</div>
								</div>
							</div>
						</div>

						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/health-app-slide3.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-2 number">3</div>
									<div class="col-10 text">
										<p v-html="$t('health_app_3')"></p>
									</div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
					<div class="col-12 text-center my-4 mt-5">
						<a :href="urlWebTelemed" class="btn-demo" target="_blank" v-html="$t('Consult a doctor now')"></a>
					</div>
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-12 text-center my-4">
						<a id="clinic"></a>
						<h2 class="strike-right"><b v-html="$t('CLINIC MANAGEMENT')"></b></h2>
					</div>
					<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/clinic-manage-slide1.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-2 number">1</div>
									<div class="col-10 text">
										<p v-html="$t('clinic_management_1')"></p>
									</div>
								</div>
							</div>
						</div>

						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/clinic-manage-slide2.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-2 number">2</div>
									<div class="col-10 text">
										<p v-html="$t('clinic_management_2')"></p>
									</div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
					<div class="col-12 text-center my-4 mt-5">
						<a href="#demo" class="btn-demo" v-html="$t('REQUEST DEMO')"></a>
					</div>
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-12 text-center my-4">
						<a id="market"></a>
						<h2 class="strike-left"><b>HEALTH MARKETPLACE</b></h2>
					</div>
					<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/marketplace-slide1.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-12 text text-center">
										<p v-html="$t('Gathering up to 1,000 special health packages with the medical standard through online. Shop now!')"></p>
									</div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
					<!-- <div class="col-12 text-center my-4 mt-5">
						<a :href="urlMarketplace" class="btn-demo" target="_blank" v-html="$t('Shop now')"></a>
					</div> -->
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-12 text-center my-4">
						<a id="referral"></a>
						<h2 class="strike-right"><b>REFERRAL SYSTEM</b></h2>
					</div>
					<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
						<div class="product-slide-itemc">
							<div class="product-slide-item">
								<div class="image"><img src="@/assets/images/referral-slide1.jpg" class="ir" /></div>
								<div class="content row">
									<div class="col-12 text text-center">
										<p v-html="$t('Transferring patient from Hospital A to Hospital B with Effective Health Record and User-friendly Interface')"></p>
									</div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
					<div class="col-12 text-center my-4 mt-5">
						<a href="#demo" class="btn-demo" v-html="$t('REQUEST DEMO')"></a>
					</div>
				</div>
			</div>
		</div>

		<a id="demo"></a>
		<div class="container-fluid bg-form6">
			<div class="container py-0 py-md-5">
				<div class="row">
					<div class="col-lg-6 py-5">

						<form class="apply" @submit.prevent="sendRequestDemo">
							<div class="row">
								<div class="col-12">
									<h1 class="text-bold" v-html="$t('REQUEST A DEMO')"></h1>
								</div>
								<div class="col-12">
									<p v-html="$t('Complete the form below, and we will contact you to schedule a demo within 1-2 business days')"></p>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Company Name')"></label>
									<input type="text" required v-model="model.companyName" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Your Name')"></label>
									<input type="text" required v-model="model.contactName" />
								</div>
								<div class="col-12">
									<label v-html="$t('Industry Type')"></label>
									<select required v-model="model.industryType">
										<option value="" selected disabled v-html="$t('Select')"></option>
										<option value="โรงพยาบาล และคลีนิค" v-html="$t('HOSPITAL & CLINIC SOLUTION')"></option>
										<option value="ธุรกิจภาครัฐ" v-html="$t('SMART CITY SOLUTION')"></option>
										<option value="ธุรกิจประกัน" v-html="$t('INSURANCE SOLUTION')"></option>
										<option value="ธุรกิจบริษัทเอกชน" v-html="$t('EMPLOYEE\'S HEALTH BENEFIT SOLUTION')"></option>
										<option value="ธุรกิจส่งเสริมการท่องเที่ยว" v-html="$t('MEDICAL TOURISM & HOSPITALITY SOLUTION')"></option>
									</select>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Phone')"></label>
									<input type="tel" maxlength="10" required v-model="model.phone" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('E-mail')"></label>
									<input type="email" required v-model="model.email" />
								</div>
								<div class="col-12">
									<label v-html="$t('Message')"></label>
									<textarea class="none-resize" required v-model="model.message"></textarea>
								</div>
								<div class="col-12 text-center text-md-left">
									<input type="submit" class="btn-submit" :value="$t('CONFIRM')" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<a id="qna"></a>
		<div class="container-fluid bg-white">
			<div class="container-md py-5 px-3 px-md-5">
				<div class="row">
					<div class="col-12 text-center">
						<h2><b v-html="$t('Browse Questions')"></b></h2>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna1}" @click.prevent="toggleActive('qna1')" v-html="$t('pq1')"></div>
							<div class="content" :class="{'active': this.qna1}">
								<div class="content-item" v-html="$t('pa1')"></div>
							</div>
						</div>
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna2}" @click.prevent="toggleActive('qna2')" v-html="$t('pq2')"></div>
							<div class="content" :class="{'active': this.qna2}">
								<div class="content-item" v-html="$t('pa2')"></div>
							</div>
						</div>
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna3}" @click.prevent="toggleActive('qna3')" v-html="$t('pq3')"></div>
							<div class="content" :class="{'active': this.qna3}">
								<div class="content-item" v-html="$t('pa3')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a id="testimonial"></a>
		<div class="container py-0 pb-md-5">
			<div class="row">
				<div class="col-12 py-5 text-center">
					<h2><b v-html="$t('Testimonials')"></b></h2>
				</div>
				<div class="col-12 pb-5 text-center">
					<VueSlickCarousel :autoplay="true" :speed="1500" :pauseOnHover="false" class="row testimonial-slide">
						<div class="col-12">
							<div class="row">
								<div class="col-md-3 text-center px-4">
									<div class="image-container"><i class="quote"></i>
										<div class="image"><img src="@/assets/images/media/testimonial1.jpg" class="ir" /></div>
									</div>
								</div>
								<div class="col-md-9 px-5">
									<div class="content" v-html="$t('Doctor A to Z is engaged in not just offering technology, but helping us solve problems - and that’s what we need')"></div>
									<div class="by"><b>Doctor A to Z | </b><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i></div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {
		VueSlickCarousel
	},
	data: () => ({
		urlMarketplace: process.env.VUE_APP_URL_MARKETPLACE,
		urlWebTelemed: process.env.VUE_APP_URL_WEB_TELEMED,
		qna1: false,
		qna2: false,
		qna3: false,
		model: {
			companyName: '',
			contactName: '',
			industryType: '',
			phone: '',
			email: '',
			message: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Request demo from website Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		toggleActive(name) {
			this[name] = !this[name]
		},
		async sendRequestDemo() {
			let html = ''
			html += '<div>Company Name: ' + this.model.companyName + '</div><br/>';
			html += '<div>Contact Name: ' + this.model.contactName + ' </div><br/>';
			html += '<div>Industry Type: ' + this.model.industryType + ' </div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Message ' + this.model.message + ' </div><br/><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {
				alert(this.$t('Contact information has been sent successfully'));
				this.model = {
					companyName: '',
					contactName: '',
					industryType: '',
					phone: '',
					email: '',
					message: ''
				}
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	},
}
</script>